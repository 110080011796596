




































































import { Component, Prop, mixins } from 'nuxt-property-decorator';
import { isSameDay } from 'date-fns';

import { usePerformanceEvents } from '@/composables/performance-events';
import { formatSv } from '@/composables/datefns';
import { capitalizeFirst } from '@/composables/utils';

import { IconAngleLeft, IconAngleRight } from '@/icons';
import { ComposableMixin } from '@/mixins/composable';

@Component({
  components: {
    IconAngleLeft,
    IconAngleRight,
  },
  setup (props: any) {
    return usePerformanceEvents(props.performanceId);
  },
})
export default class ShowsCalendar extends mixins(ComposableMixin(usePerformanceEvents, false)) {
  date: Date = new Date();

  @Prop({ type: String, required: false })
  performanceId!: string;

  get year () {
    return this.date.getFullYear();
  }

  get month () {
    return this.date.getMonth();
  }

  get allEvents () {
    return this.performanceEvents.map((event, index) => ({
      index,
      ...event,
    }));
  }

  get calendarAttributes () {
    return [
      {
        highlight: {
          fillMode: 'solid',
        },
        dates: this.allEvents.map(event => event.startDate),
      },
    ];
  }

  // calendarAttributes = [
  //   {
  //     highlight: {
  //       fillMode: 'solid',
  //     },
  //     dates: new Date(this.year, this.month, 18),
  //   },
  //   {
  //     highlight: {
  //       fillMode: 'solid',
  //     },
  //     dates: new Date(this.year, this.month, 19),
  //   },
  //   {
  //     highlight: {
  //       fillMode: 'solid',
  //     },
  //     dates: new Date(this.year, this.month, 28),
  //   },
  // ];

  modalDate: Date = new Date();
  modalOpen = false;

  get modalTitle () {
    return capitalizeFirst(formatSv(this.modalDate, 'EEEE, d MMMM'));
  }

  get modalSubtitle () {
    if (this.shows.length === 0)
      return 'Inga konserter';
    else if (this.shows.length === 1)
      return '1 st konsert';
    else
      return `${this.shows.length} st konserter`;
  }

  async onDayClick (e) {
    this.modalDate = e.date;
    await this.$nextTick();

    if (this.shows.length > 0)
      this.openModal(true);
  }

  openModal (e: boolean) {
    this.modalOpen = e;
  }

  onMonthChange (page) {
    this.date = new Date(page.year, page.month);
  }

  get previousEventDay () {
    if (!this.shows.length)
      return null;

    const firstShowOfToday = this.shows[0];
    const previousEvent = this.allEvents?.[firstShowOfToday.eventIndex - 1];

    if (previousEvent)
      return new Date(previousEvent.startDate);
  }

  get nextEventDay () {
    if (!this.shows.length)
      return null;

    const lastShowOfToday = this.shows[this.shows.length - 1];
    const nextEvent = this.allEvents?.[lastShowOfToday.eventIndex + 1];

    if (nextEvent)
      return new Date(nextEvent.startDate);
  }

  get shows () {
    return this.allEvents
      .filter(event => isSameDay(new Date(event.startDate), this.modalDate))
      .map(event => ({
        title: event.performance.title,
        slug: event.performance.pageUrl,
        image: event.performance.image,
        subtitle: `${formatSv(new Date(event.startDate), "d MMM 'kl.' HH:mm")} på ${event.locationStage}`, // '5 jun kl. 18:00 i Norrköping',
        cancelled: event.cancelled,
        soldOut: event.soldOut,
        bookingLink: event.purchaseUrl,
        eventIndex: event.index,
      }));
  }

  get firstEventPage () {
    let date = this.performanceEvents[0]?.startDate;
    if (!date)
      return null;

    date = new Date(date);
    return {
      month: date.getMonth() + 1, // January is 0
      year: date.getFullYear(),
    };
  }

  // shows = [
  //   {
  //     title: 'Amélie',
  //     slug: 'cool-dude',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/2338/normal_c3d46294-baa7-4529-91f3-d3bd2dd9ec95.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: false,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Peer Gynt',
  //     slug: 'dumar',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/1979/normal_71dd5871-3b42-43aa-8aa1-ee4e0301df71.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: true,
  //     soldOut: true,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Expedition kyla',
  //     slug: 'den-svenska-sonen',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/1978/normal_8f5066ef-cf60-4909-a326-f3c94208e2b8.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: true,
  //     bookingLink: 'tix.se',
  //   },
  // ];
}
