import { getCurrentInstance, onBeforeUnmount, onMounted, onUpdated, ref } from '@nuxtjs/composition-api';

/**
 * Check if object is an HTML element
 * @param el Target object
 * @returns boolean
 */
const isHtmlElement = (el: Element) => {
  try {
    return el instanceof HTMLElement;
  } catch (e) {
    return el.constructor.name === 'Comment';
  }
};

/**
 * Use intersection observer to detect when an element is visible in viewport.
 * Useful for lazy loading queries on-demand.
 */
export const useComponentScolledIntoView = () => {
  const instance = getCurrentInstance()!;
  const scrolledIntoView = ref(false);

  // Use optional element ref to observe instead
  // of the component root element
  const scrolledIntoViewTarget = ref<Element>();

  let observer: IntersectionObserver;
  const options = {
    rootMargin: '0px',
    threshold: 0.1,
  };

  const addObserver = () => {
    if (observer)
      return;

    const targetEl = scrolledIntoViewTarget.value || instance.vnode.componentInstance!.$el;
    if (!isHtmlElement(targetEl))
      return;

    // One-off observer
    observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio > 0) {
        scrolledIntoView.value = true;
        observer.disconnect();
      }
    }, options);

    observer.observe(targetEl);
  };

  // Use multiple attempts to find ref
  onMounted(addObserver);
  onUpdated(addObserver);

  onBeforeUnmount(() => {
    if (observer)
      observer.disconnect();
  });

  return {
    scrolledIntoView,
    scrolledIntoViewTarget,
  };
};
