import { gql } from 'graphql-tag';

import { performanceEventFragment } from './performance-events';

export const performanceUpcomingEventsQuery = gql`
  query performanceUpcomingEvents ($id: ID!, $limit: Int) {
    scenkonstOtPerformanceUpcomingEvents (performanceId: $id, limit: $limit) {
      ...PerformanceEventData
    }
  }

  ${performanceEventFragment}
`;
