import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from '@nuxtjs/composition-api';

import { useComponentScolledIntoView } from './scrolled-into-view';

import { performanceEventsQuery } from '@/graphql/queries/performance-events';
import type {
  PerformanceEventDataFragment,
  PerformanceEventsQuery,
  PerformanceEventsQueryVariables,
  PerformanceUpcomingEventsQuery,
  PerformanceUpcomingEventsQueryVariables,
} from '@/graphql/generated';
import { performanceUpcomingEventsQuery } from '@/graphql/queries/performance-upcoming-events';

export type PerformanceEvent = PerformanceEventsQuery['scenkonstOtPerformanceEvents'][0];

/**
 * Performance events
 * @param id Performance ID
 * @param limit Events limit
 */
export const usePerformanceEvents = (id?: string) => {
  const { scrolledIntoView } = useComponentScolledIntoView();

  const { result, loading } = useQuery<PerformanceEventsQuery, PerformanceEventsQueryVariables>(
    performanceEventsQuery,
    { id },
    () => ({ prefetch: false, enabled: scrolledIntoView.value }),
  );

  return {
    performanceEvents: computed(() => result.value?.scenkonstOtPerformanceEvents || []),
    performanceEventsLoading: loading,
  };
};

/**
 * Upcoming performance events
 * @param id Performance ID
 * @param limit Events limit
 */
export const usePerformanceUpcomingEvents = (id: string, limit: number) => {
  const upcomingEvents = ref<PerformanceEventDataFragment[]>([]);
  const { scrolledIntoView } = useComponentScolledIntoView();

  const { result, loading } = useQuery<PerformanceUpcomingEventsQuery, PerformanceUpcomingEventsQueryVariables>(
    performanceUpcomingEventsQuery,
    { id, limit },
    () => ({ prefetch: false, enabled: scrolledIntoView.value }),
  );

  watch(result, () => {
    upcomingEvents.value = result.value?.scenkonstOtPerformanceUpcomingEvents || [];
  }, { immediate: true });

  return {
    performanceUpcomingEvents: upcomingEvents,
    performanceUpcomingEventsLoading: loading,
  };
};
