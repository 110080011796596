import { gql } from 'graphql-tag';

import { thumb1x1ImageFragment } from './image.fragments';

export const performanceEventFragment = gql`
  fragment PerformanceEventData on TixEvent {
    id
    startDate
    endDate
    soldOut
    cancelled
    purchaseUrl
    locationStage
  }
`;

export const performanceEventsQuery = gql`
  query performanceEvents ($id: ID) {
    scenkonstOtPerformanceEvents (performanceId: $id) {
      ...PerformanceEventData
      performance {
        id
        title
        pageUrl
        image {
          ...Thumb1x1ImageData
        }
      }
    }
  }

  ${thumb1x1ImageFragment}
  ${performanceEventFragment}
`;
